@import '/src/styles/utils.scss';

.formGroup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  @include mqPC() {
    flex-direction: row;
    justify-content: flex-start;
  }
}

.formGroupLeft,
.formGroupRight {
  width: auto;
  select {
    width: 100px;
  }
}

.formGroupCenter {
  justify-content: center;
  width: 5%;
  @include mqPC() {
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
  }
}

.companyName {
  max-width: 400px;
}

.salaryWrapper {
  display: flex;
  align-items: center;
  input {
    width: 50% !important;
    @include mqPC() {
      width: 30% !important;
    }
  }
}
