@import '/src/styles/utils.scss';

.formGroup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  @include mqPC() {
    flex-direction: row;
    justify-content: flex-start;
  }
}

.formGroupLeft,
.formGroupRight {
  width: auto;
  select {
    width: 100px;
  }
}

.schoolName {
  max-width: 280px;
}
.department {
  max-width: 280px;
}
