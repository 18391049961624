@import '/src/styles/utils.scss';
@import '/src/styles/fontawesome/scss/fontawesome.scss';
@import '/src/styles/fontawesome/scss/solid.scss';

.pageTop {
  position: fixed;
  right: 100px;
  bottom: 20px;
  z-index: 1;
  text-align: center;

  &::before {
    @include fa-icon-solid($fa-var-arrow-up);
    content: "\f062";
    color: white;
    font-size: 1.8rem;
    line-height: 64px;
    display: block;
    width: 64px;
    border-radius: 50%;
    background: $color-base-primary;
  }

  p {
    font-weight: bold;
    font-size: 1.4rem;
    color: $color-base-black2;
    text-shadow: 1px 1px 0 #FFFA, -1px 1px 0 #FFFA, 1px -1px 0 #FFFA, -1px -1px 0 #FFFA;
  }
}
