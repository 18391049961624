@import '/src/styles/utils.scss';

.bar {
  display: flex;
}

.block {
  background-color: #cccccc;
  width: 13%;
  height: 8px;
  margin: 0 0.5%;
}

.colorized {
  background-color: $color-base-primary;
}
