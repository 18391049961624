@import '/src/styles/utils.scss';
@import '/src/styles/fontawesome/scss/fontawesome.scss';
@import '/src/styles/fontawesome/scss/solid.scss';

:root {
  --selected-color: #d25e10;
}

$header-height: 79px;
$header-height-sp: 60px;

.header {
  height: $header-height;
  background-color: $color-base-white;
  transition: all 0.1s;

  @include mqSP {
    height: $header-height-sp;

    &:has(.appDownload) {
      height: $header-height-sp * 2;
    }
  }

  .appDownload {
    display: none;
    align-items: center;
    box-sizing: border-box;
    height: $header-height-sp;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 102;
    background: $color-base-white;
    border-bottom: 1px solid #eee;

    @include mqSP {
      display: block;
    }

    .close {
      @include fa-icon-solid($fa-var-xmark);
      height: 24px;
      width: 24px;
      margin: 0 16px 0 4px;
      background: $color-base-black;
      color: $color-base-white;
      text-align: center;
      border-radius: 5px;
    }

    .icon {
      width: 48px;
      height: 48px;
      margin-right: 16px;
    }

    .sellingPoint {
      line-height: 2.0rem;
    }

    .install {
      width: calc(33vw);
      min-width: 70px;
      border: 1px solid #d25e10;
      border-radius: 12px;
      background: #d25e10;
      color: #fff;
      font-weight: bold;
      text-align: center;
      line-height: 40px;
    }
  }

  nav {
    height: $header-height;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 102;
    background: $color-base-white;
    box-shadow: 10px 10px 40px #1b1b1b0f;
    transition: all 0.1s;

    @include mqSP {
      height: $header-height-sp;
    }

    &.openMenu {
      z-index: 110;
    }
  }

  @include mqSP {
    .appDownload + nav {
      top: $header-height-sp;
    }
  }
}

.container {
  display: flex;
  height: 100%;
  position: relative;
  align-items: center;
  margin: 0 auto;
}

.headerLogo {
  display: flex;
  align-items: center;
  float: left;
  margin-right: 80px;
  font-size: 1.8rem;
  letter-spacing: 0.1rem;
  color: $color-base-black;

  img {
    // SPのロゴ画像サイズ仮決め
    max-width: 60px;

    @include mqPC {
      max-width: 74px;
    }
  }

  span {
    margin-left: 26px;
  }
}

.headerSignLeft {
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  font-weight: bold;

  a {
    white-space: nowrap;
    color: $color-base-black;
  }

  &.selected {
    height: 64px;
    border-bottom: 1px solid var(--selected-color);
    margin-bottom: -1px;
    a {
      color: var(--selected-color);
    }
  }
}

.headerSignRight {
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  margin-left: auto;
  .headerItem {
    color: $color-base-black;
    margin-right: 12px;
    @include mqPC {
      margin-right: 24px;
    }
    &.selected {
      color: var(--selected-color);
    }
  }
  .pc {
    display: none;
    @include mqPC {
      display: block;
    }
  }
  .toggle {
    .toggleTitle {
      display: flex;
      align-items: center;
      white-space: nowrap;
      @include mqSP {
        display: none;
      }

      .icon {
        padding: 6px;
        margin: 0 8px 0 20px;
        pointer-events: none;
        transition: all 0.3s ease-out;
        &.open {
          transform: rotateX(180deg);
        }
      }

      .usericon {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        margin-right: 20px;
        background: url(../../../images/noimage.png) center center / cover;
      }
      .username {
        font-weight: bold;
      }
    }

    .toggleTitleSp {
      display: flex;
      flex-direction: column;
      align-items: center;
      @include mqPC {
        display: none;
      }

      @include fa-icon-solid($fa-var-bars);
      &::before {
        font-size: 1.8rem;
      }

      span {
        margin-top: 4px;
        font-size: 1.2rem;
      }
    }
  }

  a:not(:last-child) {
    margin-right: 16px;
  }
}

$item-width: 238px;

.menuEnterActive {
  opacity: 0;
}

.menuEnterDone {
  .menuBack {
    width: 100vw;
    opacity: 1.0;
  }

  .menu {
    transition: all 0.3s ease-in-out;
    transform: scaleY(1);

    @include mqSP {
      transform: translateX(0%);
    }
  }
}

.menuBack {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 101;
  height: 100vh;
  width: 0;
  background: #0007;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;

  @include mqPC {
    display: none;
  }

  .close {
    position: fixed;
    top: 12px;
    right: $item-width + 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.4rem;
    color: white;

    @include fa-icon-solid($fa-var-xmark);
    &::before {
      font-size: 2.2rem;
    }

    > span {
      margin-top: 4px;
    }
  }
}

.menu {
  transform: scaleY(0);
  transform-origin: center top;
  transition: all 0.3s ease-in-out;

  background-color: #eaeaea;
  position: absolute;
  top: 64px;
  right: 16px;
  z-index: 102;

  @include mqSP {
    transform: translateX(100%);
    transition: all 0.05s ease-in-out;
    position: fixed;
    top: 0;
    right: 0;
  }

  &.hide {
    display: none;
  }

  .userSP {
    display: none;
    padding: 10px;
    align-items: center;

    @include mqSP {
      display: flex;
    }

    .usericon {
      height: 40px;
      width: 40px;
      border-radius: 50%;
      margin-right: 20px;
      background: url(../../../images/noimage.png) center center / cover;
    }
    .username {
      font-weight: bold;
    }
  }

  .item {
    display: flex;
    padding: 0 24px;
    justify-content: space-between;
    align-items: center;
    background-color: $color-base-white;
    font-size: 1.4rem;
    font-weight: 1.4rem;
    color: $color-base-black;
    height: 60px;
    width: $item-width;
    border: 1px solid #eaeaea;
    @include mqSP {
      height: 50px;
      border: 0;
    }

    &:hover {
      background-color: #f0f0f0;
    }
    &:not(:last-child) {
      margin-bottom: 4px;
      @include mqSP {
        margin-bottom: 0;
      }
    }
    &.logout {
      justify-content: center;
      color: $color-base-primary;
      @include mqSP {
        background: $color-base-black;
        color: white;
      }
    }
    &.sp {
      display: flex;
      @include mqPC {
        display: none;
      }
    }
    .icon {
      margin-left: 12px;
      content: '';
      display: block;
      width: 8px;
      height: 8px;
      border-left: 1px solid;
      border-bottom: 1px solid;
      transform: rotate(-135deg);
      pointer-events: none;
    }
  }
}

.login {
  display: flex;
  align-items: center;
  margin-right: 8px;
  color: $color-base-black;

  @include fa-icon-solid($fa-var-arrow-right-to-bracket);
  &::before {
    @include mqPC {
      content: "";
    }

    font-size: 1.8rem;
  }

  @include mqSP {
    flex-direction: column;
  }

  .loginText {
    margin-top: 4px;
    font-size: 1.6rem;
    @include mqSP {
      font-size: 1.2rem;
    }
  }
}

.signup {
  display: flex;
  align-items: center;
  background: $color-base-primary;
  border: 1px solid $color-base-primary;
  border-radius: 35px;
  padding: 0 20px;
  font-size: 1.4rem;
  color: white;
  line-height: 37px;
  transition: all 0.3s;

  &:hover {
    background-color: white;
    color: $color-base-primary;
  }

  @include mqSP {
    display: none;
  }
}

.signup_sp {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $color-base-black;
  font-size: 1.2rem;

  @include fa-icon-solid($fa-var-user-pen);
  &::before {
    font-size: 1.8rem;
  }

  .signupText {
    margin-top: 4px;
  }

  @include mqPC {
    display: none;
  }
}
