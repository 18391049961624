@import '/src/styles/utils.scss';
@import '/src/styles/fontawesome/scss/fontawesome.scss';
@import '/src/styles/fontawesome/scss/solid.scss';

.selectDropdownCont {
  position: relative;
  font-size: 1.6rem;

  @include mqSP {
    font-size: 1.2rem;
  }

  select {
    display: none;
    width: 0;
    height: 0;
    overflow: hidden;
    opacity: 0;
  }

  .selectedOption {
    position: relative;
    height: 100%;
    width: 100%;
    border: 1px solid #D1D1D1;
    border-radius: 12px;
    padding: 0 60px 0 20px;
    background: white;
    cursor: pointer;

    @include mqSP {
      padding: 0 40px 0 20px;
    }

    &:focus {
      border-color: $color-base-primary;
    }

    &.activate {
      border-color: $color-base-primary;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    &::after {
      @include fa-icon-solid($fa-var-caret-down);
      content: "\f0d7";
      position: absolute;
      top: calc(50% - 1.4rem);
      right: 30px;
      width: auto;
      height: auto;
      border: 0;
      font-size: 2.5rem;
      transform: none;

      @include mqSP {
        top: calc(50% - 1.0rem);
        right: 15px;
        font-size: 1.8rem;
      }
    }
  }

  ul.options {
    display: none;
    position: absolute;
    z-index: 100;
    left: 0;
    border-left: 1px solid $color-base-primary;
    border-right: 1px solid $color-base-primary;
    border-bottom: 1px solid $color-base-primary;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    background: white;
    overflow: hidden;

    li {
      line-height: 1em;

      button {
        width: 100%;
        padding: 15px 20px;
        cursor: pointer;

        @include mqSP {
          padding: 10px 20px;
        }

        &.selected {
          font-weight: bold;
        }

        &:hover, &:focus {
          background-color: #007bff;
          color: white;
        }
      }

      &:not(:last-child) button {
        border-bottom: 1px solid $color-base-primary;
      }
    }
  }

  .selectedOption.activate + ul.options {
    display: block;
  }

  select:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.3);
  }

  option {
    background-color: #f9f9f9;
  }

  &.style-red {
    button {
      background: #FFDECF;
      color: $color-base-primary;

      &.activate {
        border-bottom: 1px solid transparent;
      }
    }

    > button {
      border-radius: 24px;
      font-weight: bold;

      @include mqSP {
        border-radius: 17px;
      }
    }

    ul {
      border-bottom-left-radius: 24px;
      border-bottom-right-radius: 24px;

      @include mqSP {
        border-bottom-left-radius: 17px;
        border-bottom-right-radius: 17px;
      }

      li:not(:last-child) button {
        border-bottom: 0 none;
      }
    }
  }
}
