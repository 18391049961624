@import '/src/styles/utils.scss';

.panelDefaultTitle {
  position: relative;
  display: flex;
  color: $color-base-black;

  &:hover {
    background-color: #f0f0f0;
  }

  &::after {
    position: absolute;
    content: '';
    display: block;
    top: 50%;
    right: 3%;
    width: 8px;
    height: 8px;
    border-left: 1px solid;
    border-bottom: 1px solid;
    transform: rotate(-135deg);
    pointer-events: none;
  }
}
