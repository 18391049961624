@import '/src/styles/utils.scss';

.selectWrapper {
  max-width: 280px;
  width: 100%;
  position: relative;
  .selectLabel {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 6px 8px;
    width: 100%;

    @include mqPC {
      max-width: 280px;
      padding: 6px 8px;
    }

    position: absolute;
    width: 100%;
    z-index: 0;

    &::after {
      position: absolute;
      content: '';
      display: block;
      width: 8px;
      height: 8px;
      top: 35%;
      right: 10px;
      border-left: 1px solid $color-gray;
      border-bottom: 1px solid $color-gray;
      transform: rotate(-45deg);
      pointer-events: none;
    }

    &.readonly {
      background-color: #eaeaea;
      color: #757575;
    }
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: block;
    cursor: pointer;

    width: 100%;
    border: none;
    opacity: 0;
    position: relative;
    z-index: 0;
  }
}

.gray {
  color: #757575;
}

.black {
  color: #000000;
}
