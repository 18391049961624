@import 'src/styles/utils.scss';

.jobOfferBubble {
  width: 600px;
  height: fit-content;
  background-color: #f6f6f6;
  border-radius: 12px;
  padding: 20px;
  margin-top: 17px;
  .jobOfferInfo {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 20px;
    padding-left: 10px;
  }

  .verticalBar {
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 100%;
    background: #889DC7;
    opacity: 1;
  }

  .jobOfferName {
    display: block;
    font: normal normal bold 14px/20px Noto Sans JP;
    color: #00256F;
  }

  .jobOfferTitle {
    display: block;
    font: normal normal normal 14px/20px Noto Sans JP;
    color: #00256F;
  }

  .jobOfferImage {
    display: block;
    justify-content: center;
    align-items: center;
    background-color: #f6f6f6;
    border-radius: 12px;

    width: 60%;
    @include mqPC {
      width: 200px;
      height: fit-content;
    }
    img {
      width: 50%;
      height: fit-content;
    }
  }
}
