@import '/src/styles/utils.scss';

.header {
  height: 64px;
  width: 100%;
  padding: 18px;
  font-size: 1.6rem;
  text-align: center;
  border-bottom: 1px solid #ddd;
}

.messageListArea {
  height: calc(100% - 64px);
  overflow-y: scroll;
  scrollbar-width: none; /*Firefox対応のスクロールバー非表示コード*/
  -ms-overflow-style: none;/*Internet Explore対応のスクロールバー非表示コード*/

  &::-webkit-scrollbar {
    display: none; /*Google Chrome、Safari、Microsoft Edge対応のスクロールバー非表示コード*/
  }

  &.loading {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
