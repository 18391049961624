@import '/src/styles/mixins.scss';

.jobList {
  min-height: 200px;

  &.loading {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .empty {
    text-align: center;
    color: #BEBEBE;
  }

  ul {
    width: 100%;

    li {
      border-radius: 20px;
      overflow: hidden;

      &:not(:last-child) {
        margin-bottom: 12px;
      }

    }
  }
  &.grid {
    @include mqPC {
      ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        &::after{
          content:"";
          display: block;
          width: 32.5%;
        }

        li {
          width: 32.5%;
          margin-bottom: 12px;
        }
      }
    }
  }
}
