@import '/src/styles/utils.scss';

.leadContent {
  font-size: 2.4rem;
  font-weight: bold;
  text-align: center;
  line-height: 150%;
  margin-bottom: 44px;
}

.followContent {
  font-size: 1.8rem;
  text-align: center;
  line-height: 150%;
}

.buttonGroup {
  border-top: 1px solid #eaeaea;
  margin: 0 40px;
}

.emphasis {
  color: #d25e10;
}
