@import '/src/styles/utils.scss';

.tableHeader {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.marginLeft16 {
  margin-left: 16px;
}

.tableDefault {
  width: 100%;
  font-size: 1.8rem;

  tr {
    display: flex;
    flex-direction: column;
    @include mqPC {
      flex-direction: row;
    }

    th {
      display: block;
      width: 100%;
      @include mqPC {
        width: 30%;
      }
    }

    td {
      display: block;
      width: 100%;
    }
  }

  .skillContent {
    width: 100%;
    padding: 0;
    border-bottom: 0;
  }

  .skillTitleSp {
    display: block;
    @include mqPC {
      display: none;
    }
  }

  .skillWrapper {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #eaeaea;
    padding: 10px 0;
    min-height: 80px;

    .skillTitlePc {
      display: none;
      @include mqPC {
        display: flex;
        align-items: center;
        width: 30%;
      }
    }

    .skill {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 100%;

      .skillFormsWrapper {
        display: flex;
        width: 100%;

        .skillForm {
          width: 55%;
          margin-right: 5%;
          @include mqPC {
            width: 300px;
            margin-right: 10%;
          }
        }

        .skillLevelForm {
          display: flex;
          align-items: center;
          margin-right: 10px;
          select {
            width: 105px;
          }
          @include mqPC {
            margin-right: 20px;
          }
        }

        .removeButton {
          display: flex;
          align-items: center;
          color: #c4c4c4;
          button {
            margin-top: 5px;
            svg {
              transform: scale(1.4);
            }
          }
        }
      }

      .errorField {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-self: flex-start;
      }
    }
  }
}

.addWorkHistoryWrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
}

.panelDefault {
  margin-bottom: 12px;
  .panelDefaultTitle {
    padding: 8px 24px;
  }
  .panelDefaultContent {
    padding: 0 24px 12px 24px;
  }
}

.tableDefault {
  tr th {
    padding: 6px 0;
    font-size: 1.4rem;
  }
  tr td {
    padding: 6px 0;
    font-size: 1.4rem;
  }
}
