// ==========================================================================
// Font Include
// ==========================================================================
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

// ==========================================================================
// Common
// ==========================================================================
html {
  font-size: 62.5%;
}

body {
  font-family: 'Noto Sans JP', sans-serif;
  font-feature-settings: 'palt';
  // letter-spacing: 0.08em;
  line-height: 1.75;
  position: relative;
  font-size: 1.6rem;
  color: #202020;
}

main {
  max-width: 1068px;
  margin: 0 auto;
}

a {
  color: $color-base-primary;
}

textarea {
  height: 260px;
  resize: none;
}

textarea,
input[type='text'],
input[type='email'],
input[type='password'] {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  width: 100%;

  @include mqPC {
    max-width: 560px;
    padding: 8px;
  }
}

input[type='tel'],
input[type='number'],
select {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 6px 8px;
  width: 100%;

  @include mqPC {
    max-width: 280px;
    padding: 6px 8px;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

input:focus {
  border: 1px solid $color-base-primary;
  outline: none;
}

::placeholder {
  color: $color-gray;
}
/* Webkit */
::-webkit-input-placeholder {
  color: $color-gray;
  opacity: 1;
}
/* Firefox */
::-moz-placeholder {
  color: $color-gray;
  opacity: 1;
}
input:placeholder-shown {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.errorForm {
  background-color: rgba(210, 94, 16, 0.2);
}
.errorMessage {
  color: #d32121;
}

.container {
  width: 100%;
  padding: 0 16px;

  @include mqPC {
    max-width: 1152px;
    margin: 0 auto;
  }
}
.progressBar {
  margin-bottom: 30px;
}

// ==========================================================================
// Button
// ==========================================================================
.buttonGroup {
  position: relative;
}

.buttonRound {
  display: block;
  text-align: center;
  border-radius: 80px;
}

.buttonMd {
  font-size: 1.6rem;
  letter-spacing: inherit;
  font-weight: bold;
  padding: 16px;
  width: 100%;

  @include mqPC {
    max-width: 360px;
    margin: 50px auto 24px;
  }
}

.buttonPrimary {
  background-color: $color-base-primary;
  color: $color-base-white;
  line-height: 1;
  position: relative;
  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
}

.buttonPrimaryDisabled {
  background-color: #e5e5e5;
  color: #9e9e9e;
  line-height: 1;
  position: relative;
}

.buttonGhost {
  background-color: $color-base-white;
  color: $color-base-primary;
  line-height: 1;
  position: relative;
  border: 1px solid $color-base-primary;
}

.buttonOutlineLight {
  border: 1px solid $color-base-white;
  color: $color-base-white;
}

// ==========================================================================
// Radio Button
// ==========================================================================
.radioButton {
  display: inline-block;
  margin-bottom: 8px;

  @include mqPC {
    display: inline;
  }
}

.radioButtonInput {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
}

.radioButtonLabel {
  display: inline-block;
  padding: 8px 24px;
  border-radius: 4px;
  transition: all 0.2s;
  border: 1px solid #ccc;
  color: #202020;
}

.radioButtonInput:checked + .radioButtonLabel {
  border: 1px solid $color-base-primary;
  color: $color-base-primary;
}

.radioButtonInput:focus + .radioButtonLabel {
  border: 1px solid $color-base-primary;
  color: $color-base-primary;
}

// ==========================================================================
// Form
// ==========================================================================
.formGroup {
  display: flex;
}

.formGroupLeft {
  display: flex;
  margin-right: 16px;
  width: 100%;
  align-items: center;

  @include mqPC {
    margin-bottom: 0;
  }
}

.formGroupCenter {
  display: flex;
  margin-right: 16px;
  margin-left: 0;
  width: 100%;
  align-items: center;

  @include mqPC {
    margin-bottom: 0;
    margin-left: 16px;
  }
}

.formGroupRight {
  display: flex;
  width: 100%;
  align-items: center;

  @include mqPC {
    margin-left: 16px;
  }
}

// ==========================================================================
// Card
// ==========================================================================
.cardVertical {
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 16px;
}

.cardVerticalThumbnail {
  img {
    width: 100%;
  }
}

.cardVerticalBody {
  background-color: $color-base-white;
  padding: 16px;
}

.cardVerticalTitle {
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 8px;
  text-align: center;
  line-height: 1.5;
}

.cardHorizon {
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 16px;
}

.cardHorizonThumbnail {
  img {
    width: 100%;
  }
}

.cardHorizonBody {
  background-color: $color-base-white;
  padding: 16px;
}

.cardHorizonTitle {
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 8px;
  text-align: center;
  line-height: 1.5;
}

// ==========================================================================
// Panel
// ==========================================================================
.panelBg {
  background-color: #f6f6f6;
  padding: 16px 0;

  @include mqPC {
    padding: 24px 0;
  }
}

.panelDefault {
  background-color: $color-base-white;
  border-radius: 4px;
  border: 1px solid #eaeaea;
  margin-bottom: 16px;

  @include mqPC {
    margin-bottom: 24px;
  }
}

.panelDefaultTitle {
  border-bottom: 1px solid #eaeaea;
  padding: 8px 16px;
  font-weight: bold;
  font-size: 2rem;
  @include mqPC {
    padding: 16px 24px;
    font-size: initial;
  }
}

.panelDefaultContent {
  padding: 16px;

  @include mqPC {
    padding: 24px 40px;
  }
}

// ==========================================================================
// Breadcrumbs
// ==========================================================================
.breadcrumb {
  margin-bottom: 25px !important;
  font-size: 1.2rem !important;
  color: #202020 !important;
  li,
  p {
    font-size: 1.2rem !important;
    color: #202020 !important;
  }
}

// ==========================================================================
// Title
// ==========================================================================
.titleMain {
  border-left: 4px solid $color-base-primary;
  font-weight: bold;
  font-size: 1.8rem;
  padding-left: 16px;
  margin-bottom: 16px;

  @include mqPC {
    margin-bottom: 24px;
  }
}

// ==========================================================================
// Table
// ==========================================================================
.tableDefault {
  width: 100%;

  tr {
    th {
      display: block;
      font-size: 1.8rem;
      padding: 8px 0;
      font-weight: normal;

      @include mqPC {
        display: table-cell;
        width: 200px;
        border-bottom: 1px solid #eaeaea;
        padding: 24px 0;
        vertical-align: middle;
      }
    }

    td {
      display: block;
      padding-bottom: 16px;

      @include mqPC {
        display: table-cell;
        border-bottom: 1px solid #eaeaea;
        padding: 24px 0;
      }
    }
  }
}

// ==========================================================================
// CheckBox
// ==========================================================================
.checkboxDefault {
  display: none;
}

.checkboxDefaultText {
  font-size: 16px;
  padding-left: 32px;
  position: relative;
  a {
    text-decoration: underline;
  }
}

.checkboxDefaultText::before {
  background-color: $color-base-white;
  border: 1px solid $color-base-primary;
  content: '';
  display: block;
  position: absolute;
  top: 4px;
  left: 0;
  height: 16px;
  width: 16px;
}

.checkboxDefault:checked {
  & + .checkboxDefaultText::before {
    background-color: $color-base-primary;
  }
}

.checkboxDefault:checked {
  & + .checkboxDefaultText::after {
    border-bottom: 2px solid $color-base-white;
    border-right: 2px solid $color-base-white;
    content: '';
    display: block;
    position: absolute;
    top: 5px;
    left: 5px;
    height: 11px;
    width: 6px;
    transform: rotate(40deg);
  }
}

// ==========================================================================
// Rule
// ==========================================================================
.ruleSect {
  font-size: 1.4rem;
  text-align: center;
  margin-bottom: 24px;

  @include mqPC {
    margin-top: 24px;
  }
}

.ruleForget {
  text-align: center;
  display: block;
}

// ==========================================================================
// Space
// ==========================================================================
.marginBottomLg {
  margin-bottom: 40px;
}

.marginBottomMd {
  margin-bottom: 24px;
}

.marginBottom16 {
  margin-bottom: 16px;
}

.marginRight8 {
  margin-right: 8px;
}

.marginLeft8 {
  margin-left: 8px;
}

// ==========================================================================
// Width
// ==========================================================================
.widthFull {
  width: 100%;
}

// ==========================================================================
// Text
// ==========================================================================
.textCenter {
  text-align: center;
}

.whiteSpaceNowrap {
  white-space: nowrap;
}

// ==========================================================================
// Google Material Icons
// ==========================================================================
.materialIconsLarge {
  display: inline-flex;
  vertical-align: middle;
  position: absolute;
  right: 24px;
  top: 18px;
}
.materialIcons {
  display: inline-flex;
  vertical-align: middle;
  position: absolute;
  right: 24px;
}
