@import '/src/styles/utils.scss';

.buttonReturn {
  display: block;
  color: $color-base-primary;
  margin: 0 auto;

  @include mqPC {
    position: absolute;
    top: 15%;
    left: 0;
  }
}
