@import '/src/styles/utils.scss';

.scoreWrapper {
  display: flex;
  align-items: center;
  input {
    width: 70% !important;
    @include mqPC() {
      width: 30% !important;
    }
  }
  span {
    margin-left: 24px;
  }
}

.acquireLanguage {
  width: 90%;
  @include mqPC() {
    width: 70%;
  }
}
