@import '/src/styles/utils.scss';

.tableHeader {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.marginLeft16 {
  margin-left: 16px;
}
.addWorkHistoryWrapper {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 24px;
}

.panelDefaultTitle {
  display: flex;
  justify-content: space-between;
}

.deleteWorkHistory {
  font-size: 1.4rem;
  margin: 3px 0;
  padding: 0 30px;
  border-radius: 40px;
  margin-right: 5%;
  border: 1px solid #d25e10;
  color: #d25e10;
}

// workhistory
.yearMonth.formGroup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  @include mqPC() {
    flex-direction: row;
    justify-content: flex-start;
  }

  .formGroupLeft,
  .formGroupRight {
    width: auto;
    select {
      width: 100px;
    }
  }

  .formGroupCenter {
    justify-content: center;
    width: 5%;
    @include mqPC() {
      margin-bottom: 0;
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.companyName {
  max-width: 400px;
}

.salaryWrapper {
  display: flex;
  align-items: center;
  input {
    width: 70% !important;
    @include mqPC() {
      width: 30% !important;
    }
  }
}

.panelDefault {
  margin-bottom: 12px;
  .panelDefaultTitle {
    padding: 8px 24px;
  }
  .panelDefaultContent {
    padding: 0 24px 12px 24px;
  }
}

.tableDefault {
  tr th {
    padding: 6px 0;
    font-size: 1.4rem;
  }
  tr td {
    padding: 6px 0;
    font-size: 1.4rem;
  }
}
