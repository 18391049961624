@import '/src/styles/utils.scss';

.modalWrap {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
}

.container {
  width: 80%;
  height: 376px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 101;
  background-color: #fff;
}

.leadContent {
  font-size: 2.4rem;
  font-weight: bold;
  text-align: center;
  line-height: 150%;
  margin-top: 48px;
  margin-bottom: 44px;
}

.followContent {
  font-size: 1.8rem;
  text-align: center;
  line-height: 200%;
}

.buttonGroup {
  border-top: 1px solid #eaeaea;
  margin: 0 40px;
  display: flex;
}
