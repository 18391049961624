@import '/src/styles/utils.scss';
.em {
  color: red;
  font-weight: bold;
}

.container {
  > p {
    margin-bottom: 20px;
  }
}

.loadingField {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}
