@import '/src/styles/utils.scss';

.txtCenter {
  text-align: center;
}

main#main {
  padding: 67px 16px;
  @include mqSP {
    padding: 25px 16px;
  }

  .search {
    margin-bottom: 72px;
    @include mqSP {
      margin-bottom: 25px;
    }
  }

  .mainCont {
    h2 {
      border-bottom: 1px solid $color-base-primary;
      line-height: 5.0rem;
      font-size: 1.8rem;
      font-weight: bold;
      margin-bottom: 20px;
    }

    > div.bundle {
      display: flex;
      justify-content: space-between;
    }

    .pickup {
      width: 62%;
      margin-bottom: 80px;
      @include mqSP {
        width: 100%;
        margin-bottom: 40px;
      }
    }

    .info {
      width: 33%;
      @include mqSP {
        width: 38%;
      }

      .signup form {
        background: url(../../../images/signup.png) center center / cover;
        border-radius: 20px;
        padding: 20px;
        margin-bottom: 12px;
        color: white;

        h2 {
          margin-bottom: 18px;
          border-bottom: none;
          line-height: 2.4rem;
          text-align: center;

          @include mqSP {
            line-height: 2.0rem;
          }
        }

        > div {
          margin-bottom: 20px;
          line-height: 1em;
          @include mqSP {
            margin-bottom: 16px;

            &:last-child {
              margin-bottom: 8px;
            }
          }

          label {
            margin-bottom: 8px;
            font-size: 1.4rem;
            @include mqSP {
              font-size: 1.2rem;
              line-height: 1.2rem;
            }
          }

          input {
            padding: 12px 20px;
            border-radius: 8px;
            background: white;
            margin-top: 8px;
            color: $color-base-black2;

            @include mqSP {
              padding: 8px 12px;
              margin-top: 4px;
            }

            &::placeholder {
              color: #bebebe;
            }
          }

          button {
            width: 100%;
            padding: 16px;
            background: $color-base-black2;
            border: 1px solid $color-base-black2;
            border-radius: 12px;
            text-align: center;
            transition: all 0.3s;
            @include mqSP {
              padding: 12px;
              font-size: 1.4rem;
            }

            &:hover {
              background-color: white;
              color: $color-base-black2;
            }
          }

          .accepted {
            position: relative;
            padding-left: 18px;
            font-size: 1.4rem;
            color: white;
            text-align: center;
            @include mqSP {
              font-size: 1.2rem;
            }

            input {
              display: none;

              & + span::before {
                content: '';
                display: block;
                height: 16px;
                width: 16px;
                position: absolute;
                top: 3px;
                left: 0;
                border: 1px solid $color-base-primary;
                background: white;
                @include mqSP {
                  top: 2px;
                  height: 15px;
                  width: 15px;
                }
              }

              &:checked + span{
                &::before {
                  border: 1px solid #FFDECF;
                  background: #FFDECF;
                }

                &::after {
                  content: '';
                  display: block;
                  height: 9px;
                  width: 4px;
                  position: absolute;
                  top: 5px;
                  left: 6px;
                  border-bottom: 2px solid $color-base-primary;
                  border-right: 2px solid $color-base-primary;
                  transform: rotate(40deg);
                }
              }
            }

            a {
              color: white;
              text-decoration: underline;
            }
          }
        }

        .errorMessage {
          margin: 8px;
          color: black;
        }
      }

      .download {
        background: white;
        padding: 24px;
        border: 1px solid #d1d1d1;
        border-radius: 20px;

        .downloadQr {
          margin-bottom: 20px;
          font-size: 1.8rem;
          font-weight: bold;
          display: flex;
          justify-content: space-between;
          line-height: 2.1rem;
          @include mqSP {
            justify-content: space-around;
            margin-bottom: 15px;
            font-size: 1.4rem;
          }

          .small {
            font-weight: normal;
          }

          img {
            object-fit: contain;

            &.icon {
              width: 66px;
              margin-right: 8px;
              @include mqSP {
                width: 52px;
              }
            }

            &.qr {
              width: 66px;
            }
          }

        }

        .stores {
          display: flex;
          justify-content: space-between;
          @include mqSP {
            display: block;
          }

          a {
            display: block;

            &:not(:last-child) {
              margin-right: 8px;
              @include mqSP {
                margin-right: 0;
                margin-bottom: 15px;
              }
            }
          }

          .appStoreImage {
            width: 133px;
            @include mqSP {
              width: 100%;
            }

            img {
              width: 100%
            }
          }

          .googleStoreImage {
            width: 146px;
            @include mqSP {
              width: 100%;
            }

            img {
              width: 100%
            }
          }
        }
      }
    }

    .new {
      > div {
        margin-bottom: 20px;
        @include mqSP {
          margin-bottom: 13px;
        }
      }

      .more {
        display: none;
        max-width: 320px;
        padding: 16px;
        background: $color-base-black2;
        border: 1px solid $color-base-black2;
        border-radius: 12px;
        color: white;
        text-align: center;
        margin: 0 auto;
        transition: all 0.3s;

        &:hover {
          background-color: white;
          color: $color-base-black2;
        }
      }
      &:not(.loading) .more {
        display: block;
      }
    }
  }
}

.brPc {
  display: none;
  @include mqPC {
    display: block;
  }
}
.brSp {
  display: block;
  @include mqPC {
    display: none;
  }
}

