@import '/src/styles/utils.scss';

.messageSend {
  border-top: 1px solid #eaeaea;
  padding: 12px 20px;
  @include mqPC() {
    padding: 12px 40px;
  }
  .messageInputArea {
    position: relative;
    font-size: 1.2rem;
    .dummyArea {
      visibility: hidden;
      min-height: 80px;
      max-height: 300px;
      white-space: pre-wrap;
      word-wrap: break-word;
      overflow-wrap: break-word;
    }
    textarea {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      max-width: initial;
      border: initial;
      border-radius: 8px;
      background-color: #f6f6f6;
      resize: none;

      &:focus {
        background-color: $color-base-white;
        border: 1px solid #eaeaea;
      }
    }
  }
  .fileSelectArea {
    display: flex;
    flex-direction: column;
    .selectedFileArea {
      margin-top: 11px;
      font-size: 1.2rem;
      .selectedFile {
        width: fit-content;
        padding: 8px;
        border-radius: 4px;
        background-color: #f6f6f6;
        &:not(:first-child) {
          margin-top: 4px;
        }
        &:hover {
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        }
        button {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          margin-left: 12px;
        }
      }
    }
    .messageInputBottom {
      margin-top: 25px;
      display: flex;
      justify-content: space-between;
      label {
        display: flex;
        align-items: center;
        font-size: 1.2rem;
        color: $color-gray;
        cursor: pointer;
        span {
          margin-left: 8px;
        }
        input[type="file"] {
          display: none;
        }
      }

      .submitButton {
        width: 104px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 4px 40px;
        font-size: 1.2rem;
        font-weight: bold;
        color: $color-base-white;
        border-radius: 14px;
        background-color: #dd864c;
        &:hover {
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        }
        &:disabled {
          color: #707070;
          background-color: #ddd;
          &:hover {
            box-shadow: none;
          }
        }
      }
    }
  }
}
