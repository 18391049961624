@import '/src/styles/utils.scss';

.messageRoomCard {
  width: 100%;
  padding: 20.5px 20px;
  border-bottom: 1px solid #ddd;
  &.selected {
    background-color: #f0f0f0;
  }
  &:hover {
    background-color: #f0f0f0;
  }
}
.companyName {
  font-size: 1.4rem;
  font-weight: bold;
}
.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .body {
    width: 80%;
    font-size: 1.2rem;
    color: #cfcbca;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
  .statusArea {
    .notReplied {
      font-size: 1.2rem;
      color: #ce5b26;
    }
    .unread {
      height: 10px;
      width: 10px;
      border-radius: 50%;
      background-color: #3cbbb1;
    }
  }
}


