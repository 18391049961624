@import '/src/styles/utils.scss';

.leadContent {
  font-size: 2.4rem;
  font-weight: bold;
  text-align: center;
  line-height: 150%;
  margin-bottom: 44px;
  color: #d25e10;
}

.followContent {
  font-size: 1.8rem;
  text-align: center;
  line-height: 150%;
}

.buttonGroup {
  border-top: 1px solid #eaeaea;
  margin: 0 40px;
}

.anchor {
  display: block;
  text-align: center;
  position: relative;
  margin-top: 45px;
  margin-bottom: 43.5px;
}

.materialIcons {
  display: inline-flex;
  vertical-align: middle;
  position: absolute;
  right: 392px;
  top: 8px;
}
