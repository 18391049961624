@import '/src/styles/utils.scss';

:root {
  --main-height: calc(100vh - 64px - 24px);
  @include mqPC {
    --main-height: calc(100vh - 64px);
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  width: 100%;
  padding: 18px 20px;
  border-bottom: 1px solid #ddd;
  @include mqPC() {
    padding: 18px 40px;
  }
  .backButton {
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    img {
      transform: rotate(90deg);
    }
    @include mqPC {
      display: none;
    }
  }
  .companyName {
    margin: 0 20px;
    font-size: 1.4rem;
    font-weight: bold;
    @include mqPC {
      margin: initial;
    }
  }
}

.messageExchangeArea {
  display: flex;
  flex-direction: column;
  padding: 24px 20px 0;
  @include mqPC() {
    padding: 24px 40px 0;
  }

  overflow-y: scroll;
  scrollbar-width: none; /*Firefox対応のスクロールバー非表示コード*/
  -ms-overflow-style: none;/*Internet Explore対応のスクロールバー非表示コード*/

  &::-webkit-scrollbar {
    display: none; /*Google Chrome、Safari、Microsoft Edge対応のスクロールバー非表示コード*/
  }

  .message {
    display: flex;
    flex-direction: column;
    .messageBubble {
      width: fit-content;
      min-width: 40%;
      max-width: 82%;
      @include mqPC() {
        min-width: 20%;
        max-width: 51%;
      }
    }
    .iconBubble {
      margin-top: 17px;
    }
    .jobOfferBubble {
      width: fit-content;
      min-width: 40%;
      max-width: 82%;
      @include mqPC() {
        min-width: 20%;
        max-width: 51%;
      }
    }
    .date {
      color: #9a9ea2;
      font-size: 1.2rem;
      margin: 17px 0;
    }
    &.user {
      .messageBubble, .iconBubble, .date {
        align-self: flex-end;
      }
    }
  }
}

.messageSendArea {
  position: sticky;
}
