@import '/src/styles/utils.scss';

.messageBubble {
  display: flex;
  flex-direction: column;

  .body {
    padding: 20px;
    font-size: 1.2rem;
    border-radius: 12px;
    color: $color-base-black;
    background-color: #f6f6f6;
    white-space: pre-wrap;
    word-break: break-all;
  }

  &.user {
    .body {
      color: $color-base-white;
      background-color: #3cbbb1;
    }
  }
}
