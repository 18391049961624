@import '/src/styles/utils.scss';
.panelDefault {
  margin-bottom: 12px;
  .panelDefaultTitle {
    padding: 8px 24px;
  }
  .panelDefaultContent {
    padding: 0 24px 12px 24px;
  }
}

.tableDefault {
  tr th {
    padding: 6px 0;
    font-size: 1.4rem;
  }
  tr td {
    padding: 6px 0;
    font-size: 1.4rem;
  }
}
