@import '/src/styles/utils.scss';

.txtCenter {
  text-align: center;
}

main#main {
  padding: 67px 0;

  .search {
    margin-bottom: 72px;
  }

  .mainCont {
    h2 {
      border-bottom: 1px solid $color-base-primary;
      line-height: 5.0rem;
      font-size: 1.8rem;
      font-weight: bold;
      margin-bottom: 20px;
    }

    > div.bundle {
      display: flex;
      justify-content: space-between;
    }

    .pickup {
      width: 62%;
      margin-bottom: 80px;
    }

    .info {
      width: 33%;

      .signup {
        background: url(../../../images/signup.png) center center / cover;
        border-radius: 20px;
        padding: 20px;
        margin-bottom: 12px;
        color: white;

        h2 {
          margin-bottom: 18px;
          border-bottom: none;
          line-height: 2.4rem;
          text-align: center;
        }

        p {
          margin-bottom: 20px;
          line-height: 1em;

          label {
            margin-bottom: 8px;
            font-size: 1.4rem;
          }

          input {
            padding: 12px 20px;
            border-radius: 8px;
            background: white;
            margin-top: 8px;
            color: $color-base-black2;

            &::placeholder {
              color: #bebebe;
            }
          }

          button {
            width: 100%;
            padding: 16px;
            background: $color-base-black2;
            border: 1px solid $color-base-black2;
            border-radius: 12px;
            text-align: center;
            transition: all 0.3s;

            &:hover {
              background-color: white;
              color: $color-base-black2;
            }
          }

          .accepted {
            position: relative;
            padding-left: 18px;
            font-size: 1.4rem;
            color: white;
            text-align: center;

            input {
              display: none;

              & + span::before {
                content: '';
                display: block;
                height: 16px;
                width: 16px;
                position: absolute;
                top: 3px;
                left: 0;
                border: 1px solid $color-base-primary;
                background: white;
              }

              &:checked + span{
                &::before {
                  border: 1px solid #FFDECF;
                  background: #FFDECF;
                }

                &::after {
                  content: '';
                  display: block;
                  height: 9px;
                  width: 4px;
                  position: absolute;
                  top: 5px;
                  left: 6px;
                  border-bottom: 2px solid $color-base-primary;
                  border-right: 2px solid $color-base-primary;
                  transform: rotate(40deg);
                }
              }
            }

            a {
              color: white;
              text-decoration: underline;
            }
          }
        }

        .errorMessage {
          color: black;
        }
      }

      .download {
        background: white;
        padding: 24px;
        border: 1px solid #d1d1d1;
        border-radius: 20px;

        .downloadQr {
          display: flex;
          justify-content: space-between;
          margin-bottom: 20px;
          font-size: 1.8rem;
          font-weight: bold;
          line-height: 2.1rem;

          .small {
            font-weight: normal;
          }

          .qr {
            width: 66px;
          }
        }

        .stores {
          display: flex;
          justify-content: space-between;

          .appStoreImage {
            width: 133px;

            img {
              width: 100%
            }
          }

          .googleStoreImage {
            width: 146px;

            img {
              width: 100%
            }
          }
        }
      }
    }

    .new {
      margin-bottom: 67px;

      > div {
        margin-bottom: 20px;
      }

      .more {
        display: none;
        max-width: 320px;
        padding: 16px;
        background: $color-base-black2;
        border: 1px solid $color-base-black2;
        border-radius: 12px;
        color: white;
        text-align: center;
        margin: 0 auto;
        transition: all 0.3s;

        &:hover {
          background-color: white;
          color: $color-base-black2;
        }
      }
      &:not(.loading) .more {
        display: block;
      }
    }
  }
}

.brPc {
  display: none;
  @include mqPC {
    display: block;
  }
}
.brSp {
  display: block;
  @include mqPC {
    display: none;
  }
}
