@import '/src/styles/utils.scss';

section.processing {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2147483647;
  background: #{$color-base-black2}7f;
  color: white;
}

section.header {
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  left: 0px;
  z-index: 102;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  width: 100%;
  height: 163px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 12px 80px 24px;
  background: $color-base-primary center center / cover;
  box-shadow: 10px 10px 40px #1b1b1b29;
  overflow: hidden;
  color: white;

  @include mqSP {
    padding: 12px 20px 24px;
  }

  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background: #{$color-base-black2}7f;
  }

  h1 {
    font-size: 1.8rem;
    font-weight: bold;
    text-shadow: 4px 4px 12px #1b1b1b29;
  }

  .subCont {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .salary {
    font-size: 2.8rem;
    font-weight: bold;
    text-shadow: 4px 4px 12px #1b1b1b29;

    .unit {
      font-size: 1.2rem;
      font-weight: normal;
    }
  }

  button {
    width: 112px;
    padding: 0 20px;
    border: 1px solid $color-base-primary;
    border-radius: 12px;
    background: $color-base-primary;
    color: $color-base-white;
    font-weight: bold;
    text-align: center;
    line-height: 61px;
    box-shadow: 10px 10px 40px '#{$color-base-primary}29';
    transition: all 0.3s;

    &:hover {
      background-color: white;
      color: $color-base-primary;
    }
  }

  &.loadingCircle {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
  }
}

main#main {
  padding: 67px 16px;
  @include mqSP {
    padding: 25px 16px;
  }

  section {
    margin-bottom: 72px;
    @include mqSP {
      margin-bottom: 40px;
    }

    > div:not(:last-of-type) {
      margin-bottom: 40px;
    }

    h2 {
      border-bottom: 1px solid $color-base-primary;
      line-height: 50px;
      font-size: 1.8rem;
      font-weight: bold;
      margin-bottom: 20px;
    }

    h3 {
      font-weight: bold;
      margin-bottom: 20px;
    }

    .jobImages {
      .card {
        width: 100%;
        height: 331px;
        border-radius: 20px;
        background-position: center center;
        background-size: cover;

        @include mqSP {
          height: 221px;
        }
      }
    }

    dl {
      display: flex;
      flex-wrap: wrap;
      border-radius: 20px;
      background: white;
      padding: 20px;
      box-shadow: 10px 10px 40px #1b1b1b29;
      overflow: hidden;

      $dt-width: 120px;
      dt {
        display: inline-block;
        width: $dt-width;
        color: #bebebe;

        &:not(:last-of-type) {
          margin-bottom: 24px;
        }
      }

      dd {
        display: inline-block;
        width: calc(100% - $dt-width);
        color: $color-base-black2;

        &:not(:last-of-type) {
          margin-bottom: 24px;
        }
      }
    }
  }

  .loadingCircle {
    text-align: center;
  }
}
