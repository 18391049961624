@import '/src/styles/utils.scss';

.footer {
  background-color: #220c06;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1152px;
  height: 102px;
  width: 100%;
  margin: 0 auto;
}

.footerBottom {
  text-align: center;
}
