@import '/src/styles/mixins.scss';
@import '/src/styles/variables.scss';

.jobCard {
  display: block;
  height: 100%;
  padding: 20px;
  background: white;
  color: $color-base-black2;
  @include mqSP {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  h3 {
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    @include mqSP {
      white-space: normal;
      overflow: auto;
    }
  }

  .basicInfo {
    display: flex;
    justify-content: space-between;

    .salary {
      white-space: nowrap;
      font-size: 1.2rem;

      .em {
        font-size: 2.0rem;
        font-weight: bold;
        margin-right: 0.4rem;
      }
    }
  }

  hr {
    color: #D1D1D1;
    margin: 7px 0 9px;
  }

  .subInfo {
    display: flex;
    justify-content: space-between;
    font-size: 1.4rem;
    white-space: nowrap;
    @include mqSP {
      display: block;
      white-space: normal;
    }
    div {
      overflow: hidden;
    }
  }
}
