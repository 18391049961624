@import '/src/styles/utils.scss';

.iconBubble {
  display: flex;
  flex-direction: column;
  .iconWrapper {
    width: 64px;
    height: 64px;
    padding: 16px;
    background-color: #f6f6f6;
    border-radius: 12px;
    img {
      width: 100%;
    }
  }
  .imageWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
    background-color: #f6f6f6;
    border-radius: 12px;

    width: 60%;
    @include mqPC {
      height: 200px;
      width: fit-content;
    }
    img {
      height: 100%;
      width: 100%;
    }
  }
  .filenames {
    font-size: 1.2rem;
    div {
      margin-top: 13px;
    }
  }
  &.user {
    .iconWrapper, .imageWrapper, .filenames {
      align-self: flex-end;
    }
  }
}
