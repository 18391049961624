@import '/src/styles/utils.scss';

.textareaDiv {
  position: relative;
}

.placeholder {
  position: absolute;
  top: 0px;
  left: 0px;
  white-space: pre-wrap;
  padding: 8px;
  pointer-events: none;
  @include mqPC {
    max-width: 560px;
  }

  color: $color-gray;
  opacity: 1;
}
