@import '/src/styles/utils.scss';

.leadContent {
  font-size: 2.4rem;
  font-weight: bold;
  text-align: center;
  line-height: 150%;
  margin-bottom: 44px;
}

.followContent {
  font-size: 1.8rem;
  text-align: center;
  line-height: 150%;
}

.notice {
  color: $color-gray;
  font-size: 1.4rem;
  text-align: center;
  line-height: 150%;
}

.buttonGroup {
  border-top: 1px solid #eaeaea;
}

.anchor {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 45px;
  margin-bottom: 43.5px;
}

.icons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2px 0 0 10px;
}
