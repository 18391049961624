@import '/src/styles/utils.scss';

.tableHeader {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.marginLeft16 {
  margin-left: 16px;
}
.scoreWrapper {
  display: flex;
  align-items: center;
  input {
    width: 70% !important;
    @include mqPC() {
      width: 30% !important;
    }
  }
  span {
    margin-left: 24px;
  }
}

.acquireLanguage {
  width: 90%;
  @include mqPC() {
    width: 70%;
  }
}
.panelDefault {
  margin-bottom: 12px;
  .panelDefaultTitle {
    padding: 8px 24px;
  }
  .panelDefaultContent {
    padding: 0 24px 12px 24px;
  }
}

.tableDefault {
  tr th {
    padding: 6px 0;
    font-size: 1.4rem;
  }
  tr td {
    padding: 6px 0;
    font-size: 1.4rem;
  }
}
