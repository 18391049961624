@import '/src/styles/utils.scss';
.materialIcons {
  display: inline-flex;
  vertical-align: middle;
  position: absolute;
  left: 24px;
  top: 12px;
}
.buttonMd {
  max-width: 360px;
  margin: initial;
}
