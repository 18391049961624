@import '/src/styles/utils.scss';

.registrationCompleteContent {
  p {
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 150%;
    @include mqPC {
      text-align: center;
      font-size: 2.4rem;
    }
  }
}

.nextMark {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 2rem;
  font-weight: bold;
  align-items: center;
  margin-bottom: 11px;
  &::after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-left: 53px solid transparent;
    border-right: 53px solid transparent;
    border-top: 16px solid black;
  }
}

.installAppContent {
  .head {
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 150%;
    @include mqPC {
      text-align: center;
      font-size: 2.4rem;
    }
  }
  .textWrap {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    @include mqPC {
      margin-top: 46px;
    }
    p {
      font-size: 1.4rem;
      max-width: 770px;
      @include mqPC {
        text-align: center;
        font-size: 1.8rem;
      }
    }
  }
  .installAppDescription {
    display: flex;
    margin-top: 43px;
    flex-direction: column-reverse;
    @include mqPC {
      flex-direction: row;
    }
    .showingApp {
      display: flex;
      align-items: center;
      img {
        max-width: 100%;
        max-height: 476px;
        &.pc {
          display: none;
        }
        &.sp {
          display: block;
        }
        @include mqPC {
          &.pc {
            display: block;
          }
          &.sp {
            display: none;
          }
        }
      }
    }
    .installAppArea {
      color: $color-base-primary;
      font-size: 2rem;
      font-weight: bold;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      @include mqPC {
        font-size: 2.8rem;
      }

      @include mqPC {
        margin-top: 30px;
        margin-left: 43px;
      }
      p {
        @include mqPC {
          width: 350px;
          margin-left: 16px;
        }
      }
      .imageField {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 167px;
        .qrImage {
          img {
            max-width: 167px;
            width: 100%;
          }
        }
        .badges {
          margin-top: 16px;
          .appStore {
            img {
              max-width: 170px;
              width: 100%;
            }
            margin: 0 0 10px 12px;
          }
          .googlePlay {
            img {
              max-width: 213px;
              width: 100%
            }
          }
        }
      }
    }
  }
}
.buttonGroup {
  padding: 24px 0 0;
  margin-top: 76px;
  border-top: 1px solid #eaeaea;
}
.buttonMd {
  margin: initial;
  margin: 0 auto;
}
