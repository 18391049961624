@import '/src/styles/utils.scss';

main#main {
  padding: 67px 16px;
  @include mqSP {
    padding: 25px 16px;
  }

  .search {
    margin-bottom: 72px;
    @include mqSP {
      margin-bottom: 25px;
    }
  }

  .mainCont {
    margin-bottom: 67px;

    h2 {
      border-bottom: 1px solid $color-base-primary;
      line-height: 50px;
      font-size: 1.8rem;
      font-weight: bold;
      margin-bottom: 20px;
    }

    .loadingCircle {
      padding: 40px;
      text-align: center;
    }
  }
}
