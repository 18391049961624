@import '/src/styles/utils.scss';

:root {
  background-color: #f6f6f6;
}
.breadcrumb {
  display: none;
  @include mqPC {
    display: block;
  }
}

.panelBg {
  height: calc(var(--main-height-vh, 1vh) * 100 - 64px);
  @include mqPC {
    margin-bottom: 40px;
  }
}
.panelDefault {
  display: flex;
  margin: 0 calc(50% - 50vw);
  width: 100vw;
  height: calc(100% - 64px);
  min-height: 300px;
  @include mqPC {
    margin: initial;
    width: 100%;
    min-height: 700px;
  }
  .messageRoomList {
    width: 100%;
    border-right: 1px solid #ddd;
    @include mqPC {
      width: 30%;
    }
  }
  .messageExchangeList {
    // SPにおける初期表示はメッセージルーム一覧のみ表示されているため
    display: none;
    width: 100%;
    @include mqPC {
      display: block;
      width: 70%;
    }
  }

  &.selectedId {
    .messageRoomList {
      display: none;
      @include mqPC {
        display: block;
      }
    }
    .messageExchangeList {
      display: block;
    }
  }
}
