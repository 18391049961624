@import '/src/styles/utils.scss';
.footerSp {
  display: block;
  padding: 40px 9%;
  background-color: #220c06;
  color: #fff;
  @include mqPC {
    display: none;
  }
  .footerLogo {
    margin-bottom: 30px;
    line-height: 38px;
    img {
      height: 38px;
      margin-right: 28px;
    }
  }

  .link {
    font-size: 1.4rem;
    margin-bottom: 30px;
    line-height: 28px;
    ul {
      line-height: 2em;

      li {
        a {
          color: white;
        }
        &::before {
          display: inline-block;
          vertical-align: middle;
          content: '';
          margin-bottom: 2px;
          width: 4px;
          height: 4px;
          background: $color-base-white;
          border-radius: 50%;
          margin-right: 10px;
        }
      }
    }

    .divider {
      margin-top: 20px;
    }
  }

  .footerBottom {
    text-align: center;
  }
}

.footerPc {
  background-color: #220c06;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  display: none;
  @include mqPC {
    padding: 0 20%;
    display: block;
  }
  @include mqTab {
    padding: 0 10%;
    display: block;
  }
  .footerContainer {
    padding: 80px 0 45px;
  }
  .footerLogo {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    img {
      height: auto;
      width: 88px;
    }
    .logoText {
      margin-left: 30px;
      font-size: 14px;
    }
  }

  .footerContent {
    font-style: normal;
    font-weight: normal;
    display: flex;
    padding-bottom: 64px;
    margin-bottom: 40px;
    background-clip: padding-box;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    @include mqTab {
      justify-content: space-evenly;
    }
    .text {
      font-size: 1.4rem;
      line-height: 175%;
      width: 360px;
      margin-right: 64px;
      @include mqTab {
        display: none;
      }
    }
    .link {
      font-size: 1.6rem;
      margin-right: 64px;
      li {
        a {
          color: white;
        }
        &::before {
          display: inline-block;
          vertical-align: middle;
          content: '';
          margin-bottom: 2px;
          width: 4px;
          height: 4px;
          background: $color-base-white;
          border-radius: 50%;
          margin-right: 10px;
        }
      }
    }
  }

  .footerStoreImages {
    margin-bottom: 80px;
    div {
      display: inline-block;
    }
    div:first-child {
      @include mqTab {
        margin-bottom: 40px;
      }
    }
    span {
      font-size: 2rem;
      font-weight: bold;
      margin-right: 40px;
      vertical-align: top;
    }
    .qr {
      height: 100px;
      width: auto;
    }
    .googleStoreImage {
      img {
        margin-top: -10px;
        height: 60px;
      }
    }
    img {
      margin-right: 25px;
      height: 40px;
      width: auto;
      vertical-align: top;
    }
  }

  .footerBottom {
    text-align: center;
  }

  &.simple {
    padding: 0 10%;

    .footerContainer {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      padding: 40px 0;

      .footerLogo {
        margin: 0;
        margin-right: 40px;

        img {
          width: 152px;
        }
      }

      .footerContent {
        padding: 0;
        margin: 0;
        border-bottom: 0;

        .link {
          margin: 0;

          ul li {
            display: inline-block;

            &::before {
              display: none;
            }

            padding: 0 8px;

            &:first-child {
              padding-left: 0;
            }
            &:last-child {
              padding-right: 0;
            }
            &:not(:last-child) {
              border-right: 1px solid #FFFFFF7F;
            }
          }
        }
      }

      .footerBottom {
        text-align: left;
        white-space: nowrap;
      }
    }

  }
}
