@import '/src/styles/utils.scss';

.panelDefaultContent {
  padding: 16px;

  @include mqPC {
    padding: 24px 16% 40px;
  }
}

.form {
  margin-top: 40px;
  textarea {
    max-width: 100%;
  }

  .buttonMd {
    margin: 50px auto 0;
  }

  .buttonDanger {
    background-color: $color-base-black;
    color: $color-base-white;
    line-height: 1;
    position: relative;
    &:hover {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }
  }

  .buttonDangerDisabled {
    background-color: #e5e5e5;
    color: #9e9e9e;
    line-height: 1;
    position: relative;
  }
}
