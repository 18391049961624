@import '/src/styles/utils.scss';

.dropdownIndicator {
  width: 10px;
  &::after {
    margin-bottom: 4px;
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    border-left: 1px solid $color-gray;
    border-bottom: 1px solid $color-gray;
    transform: rotate(-45deg);
    pointer-events: none;
  }
}
