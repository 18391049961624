@import '/src/styles/utils.scss';

.name {
  align-items: center;
  display: block;
  @include mqPC {
    display: flex;
  }
  .formGroupLeft {
    width: auto;
    margin-right: 0;
    margin-bottom: 8px;
    @include mqPC {
      margin-right: 16px;
      margin-bottom: 0;
    }
    span {
      width: 50px;
    }
  }

  .formGroupRight {
    width: auto;
    span {
      width: 50px;
    }
  }
}

.birth.formGroup {
  .formGroupLeft {
    width: auto;
    select {
      max-width: 100px;
      min-width: 75px;
      @include mqPC {
        width: 100px;
      }
    }
  }
  .formGroupCenter,
  .formGroupRight {
    width: auto;
    select {
      max-width: 100px;
      min-width: 60px;
      @include mqPC {
        width: 100px;
      }
    }
  }
}

.phoneWrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .countryNumber {
    margin-right: 10px;
    select {
      width: 100px;
    }
  }
  .phone {
    input {
      max-width: 150px;
      @include mqPC {
        max-width: 220px;
      }
    }
  }
}

.privatePc {
  width: 73px;
  height: 32px;
  white-space: nowrap;
  background: #dddddd;
  border-radius: 5px;
  margin-left: 20px;
  text-align: center;
  color: #757575;
  margin-left: 40px;
  display: none;
  @include mqPC {
    display: inline-block;
  }
}

.privateSp {
  width: 73px;
  height: 32px;
  white-space: nowrap;
  background: #dddddd;
  border-radius: 5px;
  margin-left: 20px;
  text-align: center;
  color: #757575;
  margin-left: 40px;
  display: inline-block;
  @include mqPC {
    display: none;
  }
}
