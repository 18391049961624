@import '/src/styles/utils.scss';

.tableHeader {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.marginLeft16 {
  margin-left: 16px;
}

.panelDefault {
  margin-bottom: 12px;
  .panelDefaultTitle {
    padding: 8px 24px;
  }
  .panelDefaultContent {
    padding: 0 24px 12px 24px;
  }
}

.birth.formGroup {
  .formGroupLeft {
    width: auto;
    select {
      max-width: 100px;
      min-width: 75px;
      @include mqPC {
        width: 100px;
      }
    }
  }
  .formGroupCenter,
  .formGroupRight {
    width: auto;
    select {
      max-width: 100px;
      min-width: 60px;
      @include mqPC {
        width: 100px;
      }
    }
  }
}

.phoneWrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .countryNumber {
    margin-right: 10px;
    select {
      width: 100px;
    }
  }
  .phone {
    input {
      max-width: 150px;
      @include mqPC {
        max-width: 220px;
      }
    }
  }
}

.tableDefault {
  tr th {
    padding: 6px 0;
    font-size: 1.4rem;
  }
  tr td {
    padding: 6px 0;
    font-size: 1.4rem;
  }
}
