@mixin mqPC {
  @media (min-width: 768px) {
    @content;
  }
}
.isPC {
  display: none;
  @include mqPC {
    display: block;
  }
}

@mixin mqSP {
  @media (max-width: 767px) {
    @content;
  }
}
.isSP {
  display: block;
  @include mqPC {
    display: none;
  }
}

@mixin mqTab {
  @media (min-width: 768px) and (max-width: 1024px) {
    @content;
  }
}
