@import '/src/styles/utils.scss';
@import '/src/styles/fontawesome/scss/fontawesome.scss';
@import '/src/styles/fontawesome/scss/solid.scss';

.searchJob {
  @include mqSP {
    font-size: 1.4rem;
  }

  .inputGroup {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-right: 20px;
    }
  }

  label {
    white-space: nowrap;
    margin-right: 12px;
  }

  > div {
    $columns-height: 47px;

    display: flex;
    justify-content: space-between;
    height: $columns-height;
    line-height: $columns-height;

    @include mqSP {
      display: block;
      height: auto;

      &.isPC {
        display: none;
      }
    }

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    &.left {
      justify-content: left;
    }

    &.main {
      $main-columns-height: 68px;
      $main-columns-height-sp: 50px;

      height: $main-columns-height;
      @include mqSP {
        height: auto;
      }

      .spLine {
        display: flex;
        justify-content: left;

        @include mqSP {
          margin-bottom: 8px;
          height: $main-columns-height-sp;
        }
      }

      .countryCont {
        height: 100%;
        min-width: 165px;
        margin-right: 12px;
        line-height: ($main-columns-height - 2px);
        @include mqSP {
          width: 25%;
          margin-right: 10px;
          line-height: ($main-columns-height-sp - 2px);
        }

        .country {
          font-size: 1.6rem;

          > button {
            padding-left: 52px;

            &::before {
              @include fa-icon-solid($fa-var-caret-down);
              content: "\f57e";
              position: absolute;
              top: 32%;
              left: 20px;
              font-size: 2.5rem;
              color: $color-base-primary;
              @include mqSP {
                top: 28%;
                left: 18px;
                font-size: 2.2rem;
              }
            }
          }
        }
      }

      .regionCont {
        height: 100%;
        min-width: 180px;
        margin-right: 12px;
        line-height: ($main-columns-height - 2px);
        @include mqSP {
          width: calc(75% - 10px);
          margin-right: 0;
          line-height: ($main-columns-height-sp - 2px);
        }

        .region {
          font-size: 1.6rem;

          > button {
            padding-left: 52px;

            &::before {
              @include fa-icon-solid($fa-var-caret-down);
              content: "\f3c5";
              position: absolute;
              top: 32%;
              left: 24px;
              font-size: 2.5rem;
              color: $color-base-primary;
              @include mqSP {
                top: 28%;
                left: 22px;
                font-size: 2.2rem;
              }
            }
          }
        }
      }

      .freeword {
        position: relative;
        flex: 1;
        margin-right: 12px;

        &::before {
          @include fa-icon-solid($fa-var-magnifying-glass);
          content: "\f002";
          position: absolute;
          top: 32%;
          left: 20px;
          font-size: 2.5rem;
          color: $color-base-primary;
          @include mqSP {
            top: 30%;
            font-size: 2.2rem;
          }
        }

        input {
          max-width: 100%;
          height: 100%;
          border-radius: 12px;
          padding-left: 60px;
          background: white;
          color: $color-base-primary;

          &::placeholder {
            color: #bebebe;
          }
        }
      }

      button.submit {
        width: 112px;
        border: 1px solid $color-base-primary;
        border-radius: 12px;
        background: $color-base-primary;
        color: $color-base-white;
        font-weight: bold;
        text-align: center;
        line-height: ($columns-height - 2px);
        transition: all 0.3s;

        @include mqSP {
          width: 80px;
        }

        &:hover {
          background-color: white;
          color: $color-base-primary;
        }
      }
    }

    &.spLine {
      display: flex;
      justify-content: left;
      line-height: 32px;

      > div:not(:last-child) {
        margin-right: 8px;
      }

      @include mqPC {
        &.isSP {
          display: none;
        }
      }
    }

    .periodCont {
      .period {
        min-width: 150px;
      }
    }

    .periodSPCont {
      font-size: 1.2rem;

      .periodSP {
        min-width: 120px;
      }
    }

    .salaryCont {
      .salary {
        min-width: 170px;
      }
    }

    .salarySPCont {
      font-size: 1.2rem;

      .salarySP {
        min-width: 120px;
      }
    }
  }
}
