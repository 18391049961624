@import '/src/styles/utils.scss';

.unauthenticated {
  display: flex;
  color: #d32121;
  flex-wrap: wrap;
}

.panelDefault {
  margin-bottom: 12px;
  .panelDefaultTitle {
    padding: 8px 24px;
    .userOriginalId {
      margin-left: 20px;
      font-size: 1.2rem;
      font-weight: 400;
      padding: 2px 4px;
      border-radius: 4px;
      background-color: #e6e6e6;
    }
  }
  .panelDefaultContent {
    padding: 0 24px 12px 24px;
  }
}

.tableDefault {
  tr th {
    padding: 6px 0;
    font-size: 1.4rem;
  }
  tr td {
    padding: 6px 0;
    font-size: 1.4rem;
    white-space: pre-wrap;
  }
}
